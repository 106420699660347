import { AlertComponent } from './../../components/alert/alert.component';
import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { AuthWallService } from './auth-wall.service';
import { AuthService, RegistrationForm } from '../auth.service';
import { FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MatchService } from '../../match/match.service';
import { CommonModule } from '@angular/common';
import { StLinkComponent } from '@app/components/st-link/st-link.component';
import { StButtonComponent } from '@app/components/st-button/st-button.component';
import { InputComponent } from '@app/components/input/input.component';
import { PasswordCheckerComponent } from '@app/components/password-checker/password-checker.component';
import { StIconComponent } from '@app/components/st-icon/st-icon.component';
import { SvgIconComponent } from 'angular-svg-icon';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    AlertComponent,
    StLinkComponent,
    StButtonComponent,
    InputComponent,
    PasswordCheckerComponent,
    StIconComponent,
    SvgIconComponent,
  ],
  selector: 'st-auth-wall',
  templateUrl: './auth-wall.component.html',
  styleUrls: ['./auth-wall.component.scss'],
})
export class AuthWallComponent implements OnInit, OnDestroy {
  wallType:
    | 'confirmation'
    | 'registration'
    | 'login'
    | 'changePass'
    | 'changePassConfirmation'
    | 'setNewPass';

  // confirmationWall = false;
  // registrationWall = false;
  // loginWall = false;
  // changePasswordWall = false;
  // setNewPasswordWall = false;
  // changePasswordConfirmationWall = false;

  displayMode: 'flex' | 'none' = 'none';
  linkFontSizeValue = '13px';

  loginError = false;
  loginErrorMessage = '';

  formError: any = null;

  usernameControl = new FormControl('', [Validators.email]);
  passwordControl = new FormControl('', [
    Validators.required,
    Validators.minLength(8),
  ]);

  private activationToken: string;
  private userEmail: string;
  private changePasswordToken: string;
  private teacher = false;
  private loginErrorTimer: any;

  constructor(
    private authWallService: AuthWallService,
    private authService: AuthService,
    private router: Router,
    private matchService: MatchService
  ) {}

  get isActive(): boolean {
    return this.displayMode === 'flex';
  }

  ngOnInit(): void {
    this.authWallService.activated.subscribe(activation => {
      this.activationToken = activation.activationToken;
      this.userEmail = activation.email;
      this.changePasswordToken = activation.changePasswordToken;

      if (activation.registrationMode) {
        this.wallType = 'registration';
      } else if (activation.setNewPasswordMode) {
        this.wallType = 'setNewPass';
      } else if (activation.loginMode) {
        this.wallType = 'login';
      }
      this.teacher = activation.teacher;

      activation.authWallActive ? (this.displayMode = 'flex') : 'none';

      if (this.userEmail) {
        this.usernameControl.setValue(this.userEmail);
      }
    });
  }

  ngOnDestroy(): void {
    this.resetAuthWall();
    this.resetFormToClearLogin();
  }

  @HostListener('window.resize', ['event'])
  linkTextSize(event?) {
    if (window.outerWidth <= 360) {
      this.linkFontSizeValue = '2.5rem';
    } else {
      this.linkFontSizeValue = '13px';
    }
  }

  async loginWithActivation() {
    if (!this.teacher && this.activationToken) {
      try {
        this.formError = null;
        await this.authService.activateStudent({
          username: this.usernameControl.value,
          activationToken: this.activationToken,
        });

        this.login();
      } catch (error) {
        if (error?.error?.title === 'user.has.been.already.activated') {
          this.login();
        } else {
          this.formError = error;
        }
      }
    } else {
      this.login();
    }
  }

  async signup() {
    let signupForm: RegistrationForm = {
      username: this.usernameControl.value,
      password: this.passwordControl.value,
      activationToken: this.activationToken,
    };

    try {
      if (this.teacher) {
        await this.authService.activateTeacher(signupForm);
        // this.onChangeToLoginMode();
        this.login();
      } else {
        if (this.activationToken) {
          this.formError = null;

          await this.authService.activateStudent({
            username: signupForm.username,
            activationToken: signupForm.activationToken,
          });

          this.onChangeToLoginMode();
        } else {
          signupForm.choseTeacherProfileId =
            this.matchService.getSelectedTeacher()?.id;

          await this.authService.signupStudent(signupForm);
          this.onChangeToConfirmationMessageWall();
        }
      }
    } catch (error) {
      this.formError = error;
    }
  }

  onCloseClick() {
    // scroll to top
    window.scrollTo(0, 0);

    this.displayMode = 'none';
    this.resetAuthWall();
  }

  onChangeToLoginMode() {
    this.resetAuthWall();
    this.wallType = 'login';
  }

  onChangeToConfirmationMessageWall() {
    this.resetAuthWall();
    this.wallType = 'confirmation';
    this.activationToken = null;
  }

  private showErrorMessage(errorMessage: string): void {
    this.loginErrorMessage = errorMessage;
    this.loginError = true;
    this.loginErrorTimer = setTimeout(() => {
      this.loginError = false;
      this.loginErrorMessage = '';
    }, 10000);
  }

  private resetFormToClearLogin() {
    this.usernameControl.reset();
    this.passwordControl.reset();
  }

  onSendResetLink() {
    this.authService
      .sendChangePasswordLink({
        email: this.usernameControl.value,
      })
      .subscribe(next => {
        this.usernameControl.reset();
        this.resetAuthWall();
        this.wallType = 'changePassConfirmation';
      });
  }

  onChangePassword() {
    this.authService
      .changePassword({
        newPassword: this.passwordControl.value,
        changePasswordToken: this.changePasswordToken,
      })
      .subscribe(next => {
        this.passwordControl.reset();
        this.resetAuthWall();
        this.wallType = 'login';
      });
  }

  onShowChangePasswordWall() {
    this.resetAuthWall();
    this.wallType = 'changePass';
  }

  onMatchTeacher() {
    this.onCloseClick();
    this.router.navigate(['/match']);
  }

  onRegisterUserWithBoughtPlan() {
    this.resetAuthWall();

    this.wallType = 'registration';
    this.teacher = false;
  }

  private resetAuthWall() {
    this.userEmail = undefined;
    this.wallType = 'login';

    this.formError = null;
    this.resetFormToClearLogin();
  }

  async login() {
    try {
      this.formError = null;

      await this.authService.login({
        username: this.usernameControl.value,
        password: this.passwordControl.value,
        timeZoneId: Intl.DateTimeFormat().resolvedOptions().timeZone,
      });

      this.onCloseClick();
      this.router.navigate(['dashboard']);
    } catch (error) {
      this.formError = error;
    }
  }
}
