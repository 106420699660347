import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ApiErrorsService {
  constructor() {}

  getMessage(key: string): string {
    switch (key) {
      case 'there.is.another.active.payment':
        return 'There is another active payment. Please wait until it is completed.';

      case 'user.with.given.name.already.exists':
        return 'User with given email already exists. Please try to login or reset password.';

      case 'any.successful.payments.does.not.available':
        return 'Account with given email does not have any successful payments.';

      case 'user.not.found':
        return 'User with given email not found.';

      case 'user.is.not.active':
        return 'User is not active.';

      case 'password.not.match':
        return 'Given password is incorrect.';

      case 'empty.input.data':
        return 'Both email and password are required for login.';

      case 'user.has.been.already.activated':
        return 'User has been already activated. Please try to login.';

      default:
        return 'Something went wrong. Please try again later.';
    }
  }
}
