import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AuthWallService } from '../auth/auth-wall/auth-wall.service';
import { AuthService } from '@app/auth/auth.service';

@Component({
  selector: 'st-confirm-registration',
  template: ``,
})
export class ConfirmRegistrationComponent implements OnInit {
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private authWallService: AuthWallService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    let isTeacher: boolean = this.activatedRoute.snapshot.url
      .toString()
      .includes('teacher');

    const query = this.activatedRoute.snapshot.queryParams;
    const token = this.extractActivationToken(query);
    let email = this.extractEmail(query)?.trim();

    // if user is logged in, log them out
    this.authService.logout();

    // if email has space in it, it will be replaced with '+'
    if (email && email.includes(' ')) {
      email = email.replace(' ', '+');
    }

    if (isTeacher) {
      this.authWallService.activated.next({
        authWallActive: true,
        activationToken: token,
        email,
        teacher: true,
        loginMode: false,
        registrationMode: true,
      });
    } else {
      this.authWallService.activated.next({
        authWallActive: true,
        activationToken: token,
        email,
        teacher: false,
        loginMode: true,
        registrationMode: false,
      });
    }

    this.router.navigate(['/']);
  }

  extractActivationToken(queryParams: Params): string {
    return queryParams['activationToken'];
  }

  extractEmail(queryParams: Params): string {
    return queryParams['email'];
  }
}
