import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';

import { AppRoutingModule } from './app-routing.module';
import { StComponentsModule } from './components/st-components.module';

import { LandingModule } from './landing/landing.module';

import { AuthModule } from './auth/auth.module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptorService } from './auth/auth-interceptor.service';

import { ConfirmRegistrationComponent } from './confirm-registration/confirm-registration.component';
import { ChangePasswordProxyComponent } from './change-password/change-password-proxy.component';

@NgModule({
  declarations: [
    AppComponent,
    //registration
    ConfirmRegistrationComponent,
    ChangePasswordProxyComponent,
  ],
  imports: [StComponentsModule, LandingModule, AppRoutingModule, AuthModule],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
