import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { StComponentsModule } from '../components/st-components.module';
import { AuthWallComponent } from './auth-wall/auth-wall.component';

@NgModule({
  imports: [StComponentsModule, AuthWallComponent],
  exports: [RouterModule, AuthWallComponent],
})
export class AuthModule {}
