import { Component, OnInit } from '@angular/core';
import { AuthService } from './auth/auth.service';

/**
 * Flowbite is a design system for Tailwind CSS that allows you to build professional designs in minutes.
 * @see https://flowbite.com/docs/getting-started/angular/
 */
import { initFlowbite } from 'flowbite';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'st-root',
  template: `
    <st-auth-wall></st-auth-wall>
    <router-outlet></router-outlet>
  `,
  styles: [``],
})
export class AppComponent implements OnInit {
  constructor(
    private authService: AuthService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.authService.autoLogin();
    // initFlowbite();
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        setTimeout(() => {
          initFlowbite();
        });
      }
    });
  }
}
